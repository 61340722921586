$primary: #da0037;
$primary-dark: #b4002d;
$primary-black: #171717;
$primary-grey: #444444;
$primary-light: #ededed;

$primary-grey-1: #707070;
$primary-grey-2: #9b9b9b;
$primary-grey-3: #bfbfbf;
$primary-grey-4: #e9e9e9;
$primary-grey-5: #f3f3f3;
$primary-grey-6: #f5f5f5;

$white: #ffffff;

$card-border-size: 1px;
$shadow-none: 0px;
$shadow: 10px;
$shadowX: 10px;
$shadowY: 10px;
$shadow-blur: 40px;

$breakpoint-xs: 340px;
$breakpoint-sm: 720px;
$breakpoint-md: 920px;
$breakpoint-lg: 1300px;
$breakpoint-xl: 1800px;
