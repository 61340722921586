@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .header {
        padding: 3vh 3vh;
        background: radial-gradient(circle at center bottom, rgba(235, 115, 145, 0.4542191876750701) 0%, rgba(218, 0, 55, 0.014443277310924318) 35%, rgba(255, 255, 255, 1) 100%);
        background-position: center bottom;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-size: 2rem;
                font-weight: bold;
                color: $primary-black;
            }
            nav {
                display: none;
            }
        }
        &__button {
            display: flex;
            justify-content: flex-end;
            &--text {
                display: none;
                a {
                    text-decoration: none;
                    color: $white;
                }
            }
            &--icon {
                border: none;
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 1.75rem;
                font-size: 1rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                box-shadow: $shadow-none $shadowX $shadow-blur rgba($color: $primary, $alpha: 0.4);
                cursor: pointer;
                transition: all 0.05s linear;
                a {
                    text-decoration: none;
                    color: $white;
                }
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
        &__text {
            margin-top: 3.5rem;
            margin-bottom: 3rem;
            h1 {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
                color: $primary-black;
                span {
                    color: $primary;
                }
            }
        }
        &__image {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            img {
                width: 100%;
                height: 100%;
                transform: scale(1.1);
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .header {
        padding: 4vh 6vh;
        background: radial-gradient(circle at center bottom, rgba(235, 115, 145, 0.4542191876750701) 0%, rgba(218, 0, 55, 0.014443277310924318) 35%, rgba(255, 255, 255, 1) 100%);
        background-position: center bottom;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-size: 2.2rem;
                font-weight: bold;
                color: $primary-black;
            }
            nav {
                display: none;
            }
        }
        &__button {
            display: flex;
            justify-content: flex-end;
            &--text {
                display: none;
            }
            &--icon {
                border: none;
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 1.75rem;
                font-size: 1rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                box-shadow: $shadow-none $shadowX $shadow-blur rgba($color: $primary, $alpha: 0.4);
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
        &__text {
            margin-top: 3.5rem;
            margin-bottom: 1rem;
            h1 {
                font-size: 2.2rem;
                font-weight: bold;
                text-align: center;
                color: $primary-black;
                span {
                    color: $primary;
                }
            }
        }
        &__image {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 1rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .header {
        height: 100vh;
        width: 100vw;
        padding: 5vh 8vh;
        background: radial-gradient(circle at center bottom, rgba(235, 115, 145, 0.4542191876750701) 0%, rgba(218, 0, 55, 0.014443277310924318) 35%, rgba(255, 255, 255, 1) 100%);
        background-position: center bottom;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            span {
                flex: 1;
                font-size: 2.3rem;
                font-weight: bold;
                color: $primary-black;
            }
            nav {
                flex: 3;
                display: block;
                padding: 0rem 6rem;
                ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    color: $primary-black;
                    li {
                        a {
                            text-decoration: none;
                            color: $primary-black;
                        }
                        font-size: 1rem;
                        display: inline-block;
                        &:hover {
                            font-weight: 600;
                            text-decoration: solid;
                        }
                    }
                }
            }
        }
        &__button {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            &--text {
                display: block;
                border: none;
                height: 3rem;
                padding: 0rem 2rem;
                border-radius: 1.5rem;
                font-size: 0.9rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                box-shadow: $shadow-none $shadowX $shadow-blur rgba($color: $primary, $alpha: 0.4);
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
            &--icon {
                display: none;
            }
        }
        &__text {
            margin-top: 5rem;
            h1 {
                font-size: 3rem;
                font-weight: bold;
                text-align: center;
                color: $primary-black;
                span {
                    color: $primary;
                }
            }
        }
        &__image {
            flex: 6;
            img {
                width: 50rem;
                height: 25rem;
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .header {
        height: 100vh;
        width: 100vw;
        padding: 5vh 8vh;
        background: radial-gradient(circle at center bottom, rgba(235, 115, 145, 0.4542191876750701) 0%, rgba(218, 0, 55, 0.014443277310924318) 35%, rgba(255, 255, 255, 1) 100%);
        background-position: center bottom;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            span {
                flex: 1;
                font-size: 2.5rem;
                font-weight: bold;
                color: $primary-black;
            }
            nav {
                flex: 3;
                display: block;
                padding: 0rem 10rem;
                ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    color: $primary-black;
                    li {
                        font-size: 1.15rem;
                        display: inline-block;
                    }
                }
            }
        }
        &__button {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            &--text {
                display: block;
                border: none;
                height: 3rem;
                padding: 0rem 3rem;
                border-radius: 1.5rem;
                font-size: 1rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                box-shadow: $shadow-none $shadowX $shadow-blur rgba($color: $primary, $alpha: 0.4);
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
            &--icon {
                display: none;
            }
        }
        &__text {
            margin-top: 5rem;
            h1 {
                font-size: 2.8rem;
                font-weight: bold;
                text-align: center;
                color: $primary-black;
                span {
                    color: $primary;
                }
            }
        }
        &__image {
            flex: 6;
            padding-bottom: 1rem;
            img {
                width: 40rem;
                height: 20rem;
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .header {
        height: 100vh;
        width: 100vw;
        padding: 3vh 10vh;
        background: radial-gradient(circle at center bottom, rgba(235, 115, 145, 0.4542191876750701) 0%, rgba(218, 0, 55, 0.014443277310924318) 50%, rgba(255, 255, 255, 1) 100%);
        background-position: center bottom;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__topbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            span {
                flex: 2;
                font-size: 2.5rem;
                font-weight: bold;
                color: $primary-black;
            }
            nav {
                display: block;
                flex: 3;
                padding: 0rem 10rem;
                ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    color: $primary-black;
                    li {
                        font-size: 1.15rem;
                        display: inline-block;
                    }
                }
            }
        }
        &__button {
            flex: 2;
            display: flex;
            justify-content: flex-end;
            &--text {
                display: block;
                border: none;
                height: 3rem;
                padding: 0rem 3rem;
                border-radius: 1.5rem;
                font-size: 1rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                box-shadow: $shadow-none $shadowX $shadow-blur rgba($color: $primary, $alpha: 0.4);
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
            &--icon {
                display: none;
            }
        }
        &__text {
            margin-top: 5rem;
            h1 {
                font-size: 4rem;
                font-weight: bold;
                text-align: center;
                color: $primary-black;
                span {
                    color: $primary;
                }
            }
        }
        &__image {
            flex: 6;
            padding-bottom: 1rem;
            img {
                width: 55rem;
                height: 28rem;
            }
        }
    }
}
