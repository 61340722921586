@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: $primary;
        padding: 2rem;
        margin-top: 2rem;
        h2 {
            color: $white;
            font-size: 2rem;
            font-weight: 600;
        }
        span {
            font-size: 1rem;
            color: $primary-grey-5;
        }
        &__social {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 2rem 0rem;
            a {
                text-decoration: none;
                color: $white;
                &:hover {
                    color: $primary;
                    cursor: pointer;
                }
            }
            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.5rem;
                height: 2.5rem;
                color: $white;
                border-radius: 1.25rem;
                border: $card-border-size solid $white;
                margin: 0.3rem;
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        h4 {
            color: $white;
            font-size: 0.9rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        &__made {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            span {
                font-size: 0.8rem;
                font-weight: 400;
                margin-right: 0.5rem;
            }
            svg {
                font-size: 1.2rem;
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .footer {
        padding: 2rem;
        margin-top: 3rem;
        h2 {
            color: $white;
            font-size: 3rem;
            font-weight: 600;
        }
        span {
            font-size: 1.2rem;
            color: $primary-grey-5;
        }
        &__social {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 3rem 0rem;
            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                height: 3.5rem;
                color: $white;
                border-radius: 1.75rem;
                border: $card-border-size solid $white;
                margin: 0.5rem;
                svg {
                    font-size: 1.3rem;
                }
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        h4 {
            color: $white;
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        &__made {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            span {
                font-size: 1rem;
                font-weight: 400;
                margin-right: 0.5rem;
            }
            svg {
                font-size: 1.5rem;
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .footer {
        padding: 2rem;
        margin-top: 3rem;
        h2 {
            color: $white;
            font-size: 3rem;
            font-weight: 600;
        }
        span {
            font-size: 1.2rem;
            color: $primary-grey-5;
        }
        &__social {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 3rem 0rem;
            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                height: 3.5rem;
                color: $white;
                border-radius: 1.75rem;
                border: $card-border-size solid $white;
                margin: 0.5rem;
                svg {
                    font-size: 1.3rem;
                }
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        h4 {
            color: $white;
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        &__made {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            span {
                font-size: 1rem;
                font-weight: 400;
                margin-right: 0.5rem;
            }
            svg {
                font-size: 1.5rem;
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .footer {
        padding: 2rem;
        margin-top: 3rem;
        h2 {
            color: $white;
            font-size: 2.8rem;
            font-weight: 600;
        }
        span {
            font-size: 1.1rem;
            color: $primary-grey-5;
        }
        &__social {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 3rem 0rem;
            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                height: 3.5rem;
                color: $white;
                border-radius: 1.75rem;
                border: $card-border-size solid $white;
                margin: 0.5rem;
                svg {
                    font-size: 1.3rem;
                }
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        h4 {
            color: $white;
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        &__made {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            span {
                font-size: 0.9rem;
                font-weight: 400;
                margin-right: 0.5rem;
            }
            svg {
                font-size: 1.3rem;
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .footer {
        padding: 2rem;
        margin-top: 3rem;
        h2 {
            color: $white;
            font-size: 3rem;
            font-weight: 600;
        }
        span {
            font-size: 1.2rem;
            color: $primary-grey-5;
        }
        &__social {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 3rem 0rem;
            &__item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                height: 3.5rem;
                color: $white;
                border-radius: 1.75rem;
                border: $card-border-size solid $white;
                margin: 0.5rem;
                svg {
                    font-size: 1.3rem;
                }
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        h4 {
            color: $white;
            font-size: 1.2rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }
        &__made {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            span {
                font-size: 1rem;
                font-weight: 400;
                margin-right: 0.5rem;
            }
            svg {
                font-size: 1.5rem;
            }
        }
    }
}
