@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .testimonials {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 3rem;
        margin-top: 2rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 0.8rem;
        }
        &__list {
            position: relative;
            margin-top: 2rem;
            border-radius: 1rem;
            padding: 1rem 2rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            svg {
                color: $primary;
            }
            p {
                font-size: 0.9rem;
                margin-top: 1rem;
                text-align: center;
            }
            img {
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 1.75rem;
                margin-top: 1.2rem;
            }
            h4 {
                font-size: 0.9rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 0.8rem;
            }
        }
        button {
            position: absolute;
            border: none;
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            font-size: 0.8rem;
            font-weight: 600;
            color: $primary;
            background-color: $white;
            cursor: pointer;
            transition: all 0.05s linear;
        }
        &__left {
            top: 50%;
            left: 0;
            box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(-50%, -50%);
        }
        &__right {
            top: 50%;
            right: 0;
            box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(50%, -50%);
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .testimonials {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1rem 3rem;
        margin-top: 2rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            position: relative;
            margin-top: 2rem;
            border-radius: 1rem;
            padding: 1rem 2rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            svg {
                color: $primary;
            }
            p {
                font-size: 0.9rem;
                margin-top: 1rem;
                text-align: center;
            }
            img {
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 1.75rem;
                margin-top: 1.2rem;
            }
            h4 {
                font-size: 0.9rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 0.8rem;
            }
        }
        button {
            position: absolute;
            border: none;
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            font-size: 0.8rem;
            font-weight: 600;
            color: $primary;
            background-color: $white;
            cursor: pointer;
            transition: all 0.05s linear;
        }
        &__left {
            top: 50%;
            left: 0;
            box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(-50%, -50%);
        }
        &__right {
            top: 50%;
            right: 0;
            box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(50%, -50%);
        }
    }
}

@media (min-width: $breakpoint-md) {
    .testimonials {
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            position: relative;
            margin-top: 2rem;
            border-radius: 1rem;
            padding: 1.5rem 2rem;
            width: 70%;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            svg {
                font-size: 1.5rem;
                color: $primary;
            }
            p {
                font-size: 1.2rem;
                margin-top: 2rem;
                max-width: 70%;
                text-align: center;
            }
            img {
                height: 4rem;
                width: 4rem;
                border-radius: 2rem;
                margin-top: 1.5rem;
            }
            h4 {
                font-size: 1.2rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 1rem;
            }
        }
        button {
            position: absolute;
            border: none;
            height: 3rem;
            width: 3rem;
            border-radius: 1.5rem;
            font-size: 1.2rem;
            color: $primary;
            background-color: $white;
            cursor: pointer;
            transition: all 0.05s linear;
        }
        &__left {
            top: 50%;
            left: 0;
            box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(-50%, -50%);
            &:active {
                box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.6);
            }
        }
        &__right {
            top: 50%;
            right: 0;
            box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(50%, -50%);
            &:active {
                box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.6);
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .testimonials {
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.3rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            position: relative;
            margin-top: 2rem;
            border-radius: 1rem;
            padding: 1.5rem 2rem;
            width: 70%;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            svg {
                font-size: 1.5rem;
                color: $primary;
            }
            p {
                font-size: 1rem;
                margin-top: 2rem;
                max-width: 90%;
                text-align: center;
            }
            img {
                height: 4rem;
                width: 4rem;
                border-radius: 2rem;
                margin-top: 1.5rem;
            }
            h4 {
                font-size: 1.1rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 0.9rem;
            }
        }
        button {
            position: absolute;
            border: none;
            height: 3rem;
            width: 3rem;
            border-radius: 1.5rem;
            font-size: 1.2rem;
            color: $primary;
            background-color: $white;
            cursor: pointer;
            transition: all 0.05s linear;
        }
        &__left {
            top: 50%;
            left: 0;
            box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(-50%, -50%);
            &:active {
                box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.6);
            }
        }
        &__right {
            top: 50%;
            right: 0;
            box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(50%, -50%);
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .testimonials {
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            position: relative;
            margin-top: 2rem;
            border-radius: 1rem;
            padding: 1.5rem 2rem;
            width: 70%;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            svg {
                font-size: 1.5rem;
                color: $primary;
            }
            p {
                font-size: 1.2rem;
                margin-top: 2rem;
                max-width: 70%;
                text-align: center;
            }
            img {
                height: 4rem;
                width: 4rem;
                border-radius: 2rem;
                margin-top: 1.5rem;
            }
            h4 {
                font-size: 1.2rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 1rem;
            }
        }
        button {
            position: absolute;
            border: none;
            height: 3rem;
            width: 3rem;
            border-radius: 1.5rem;
            font-size: 1.2rem;
            color: $primary;
            background-color: $white;
            cursor: pointer;
            transition: all 0.05s linear;
        }
        &__left {
            top: 50%;
            left: 0;
            box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(-50%, -50%);
            &:active {
                box-shadow: -$shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.6);
            }
        }
        &__right {
            top: 50%;
            right: 0;
            box-shadow: $shadowX $shadowY $shadow-blur rgba($color: $primary-black, $alpha: 0.4);
            transform: translate(50%, -50%);
        }
    }
}
