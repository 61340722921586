@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .services {
        margin-top: 2rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 0.8rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'services__item--one'
                'divider'
                'services__item--two'
                'divider'
                'services__item--three';
            row-gap: 2rem;
            margin-top: 3rem;
            padding: 0rem 2rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 3.5rem;
                height: 3.5rem;
                margin-bottom: 1.75rem;
            }
            h4 {
                font-size: 1.2rem;
                font-weight: 700;
                color: $primary-grey;
            }
            h3 {
                font-size: 0.9rem;
                font-weight: 500;
                color: $primary;
                margin-top: 1rem;
            }
            h2 {
                font-size: 0.9rem;
                font-weight: 500;
                color: $primary;
                margin-top: 1rem;
            }
        }
        &__description {
            font-size: 1.5rem;
        }
        &__skills {
            font-size: 1.5rem;
        }
        &__tools {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .services {
        margin-top: 2rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            display: grid;
            grid-template-areas: 'services__item--one services__item--two services__item--three';
            column-gap: 1.5rem;
            margin-top: 4rem;
            padding: 0rem 4rem;
            .divider {
                border: 0.01rem solid $primary-grey-4;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 3rem;
                height: 3rem;
                margin-bottom: 2.5rem;
            }
            h4 {
                font-size: 1rem;
                font-weight: 700;
            }
            h3 {
                font-size: 1rem;
                font-weight: 400;
                color: $primary;
                margin-top: 1.8rem;
            }
            h2 {
                font-size: 1rem;
                font-weight: 400;
                color: $primary;
                margin-top: 1.8rem;
            }
            p {
                font-size: 0.8rem;
            }
        }
        &__description {
            font-size: 1rem;
        }
        &__skills {
            font-size: 1rem;
        }
        &__tools {
            font-size: 1rem;
        }
    }
}

@media (min-width: $breakpoint-md) {
    .services {
        margin-top: 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            display: grid;
            grid-template-areas: 'services__item--one services__item--two services__item--three';
            column-gap: 1.5rem;
            margin-top: 6rem;
            padding: 0rem 4rem;
            .divider {
                border: 0.01rem solid $primary-grey-4;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 5rem;
                height: 5rem;
                margin-bottom: 2.5rem;
            }
            h4 {
                font-size: 1.5rem;
                font-weight: 700;
            }
            h3 {
                font-size: 1.3rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
            h2 {
                font-size: 1.3rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
        }
        &__description {
            font-size: 1.2rem;
        }
        &__skills {
            font-size: 1.2rem;
        }
        &__tools {
            font-size: 1rem;
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .services {
        margin-top: 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.3rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            display: grid;
            grid-template-areas: 'services__item--one services__item--two services__item--three';
            column-gap: 1.5rem;
            margin-top: 4rem;
            padding: 0rem 4rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 4rem;
                height: 4rem;
                margin-bottom: 2rem;
            }
            h4 {
                font-size: 1.1rem;
                font-weight: 700;
            }
            h3 {
                font-size: 1.1rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
            h2 {
                font-size: 1.1rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
            p {
                font-size: 0.9rem;
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .services {
        margin-top: 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            display: grid;
            grid-template-areas: 'services__item--one services__item--two services__item--three';
            column-gap: 1.5rem;
            margin-top: 4rem;
            padding: 0rem 6rem;
            .divider {
                border: 0.01rem solid $primary-grey-4;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                width: 5rem;
                height: 5rem;
                margin-bottom: 3rem;
            }
            h4 {
                font-size: 1.4rem;
                font-weight: 600;
            }
            h3 {
                font-size: 1.3rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
            h2 {
                font-size: 1.3rem;
                font-weight: 600;
                color: $primary;
                margin-top: 1.8rem;
            }
            p {
                font-size: 1rem;
            }
        }
        &__description {
            font-size: 1rem;
        }
        &__skills {
            font-size: 1rem;
        }
        &__tools {
            font-size: 1rem;
        }
    }
}
