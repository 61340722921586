@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .work {
        margin-top: 4rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 0.8rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'work__item--one'
                'work__item--two'
                'work__item--three'
                'work__item--four'
                'work__item--five'
                'work__item--six';
            row-gap: 1.5rem;
            margin-top: 2rem;
            padding: 0rem 3rem;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: $card-border-size solid $primary-grey-4;
            border-radius: 0.5rem;
            padding-bottom: 1rem;
            h4 {
                font-size: 0.9rem;
                font-weight: 600;
                margin-top: 1rem;
            }
            span {
                font-size: 0.8rem;
                color: $primary;
            }
        }
        &__image {
            position: relative;
            width: 100%;
            height: 15rem;
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $primary-black, $alpha: 0.4);
                z-index: 5;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 1rem;
            }
            img {
                width: 100%;
                height: 100%;
                margin-bottom: 1.5rem;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            button {
                border: none;
                height: 2rem;
                padding: 0rem 1rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .work {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'work__item--one work__item--two work__item--three'
                'work__item--four work__item--five work__item--six';
            row-gap: 1.5rem;
            column-gap: 2rem;
            margin-top: 3rem;
            width: 100vw;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: $card-border-size solid $primary-grey-4;
            border-radius: 0.5rem;
            padding-bottom: 1.5rem;
            h4 {
                font-size: 0.8rem;
                font-weight: 600;
                margin-top: 1.5rem;
            }
            span {
                font-size: 0.8rem;
                color: $primary;
            }
        }
        &__image {
            position: relative;
            width: 100%;
            height: 15rem;
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $primary-black, $alpha: 0.4);
                z-index: 5;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 1rem;
            }
            img {
                width: 100%;
                height: 100%;
                margin-bottom: 1.5rem;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            button {
                border: none;
                height: 2rem;
                padding: 0rem 1rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .work {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'work__item--one work__item--two work__item--three'
                'work__item--four work__item--five work__item--six';
            row-gap: 1.5rem;
            column-gap: 2rem;
            margin-top: 3rem;
            width: 55vw;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: $card-border-size solid $primary-grey-4;
            border-radius: 0.5rem;
            padding-bottom: 1.5rem;
            h4 {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 1.5rem;
            }
            span {
                font-size: 0.9rem;
                color: $primary;
            }
        }
        &__image {
            position: relative;
            width: 100%;
            height: 20rem;
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $primary-black, $alpha: 0.4);
                z-index: 5;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 1rem;
            }
            img {
                width: 100%;
                height: 100%;
                margin-bottom: 1.5rem;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            button {
                border: none;
                height: 2rem;
                padding: 0rem 1rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .work {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.3rem;
            margin-bottom: -0.1rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'work__item--one work__item--two work__item--three'
                'work__item--four work__item--five work__item--six';
            row-gap: 1.5rem;
            column-gap: 2rem;
            margin-top: 3rem;
            width: 80vw;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: $card-border-size solid $primary-grey-4;
            border-radius: 0.5rem;
            padding-bottom: 1.5rem;
            h4 {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 1.5rem;
            }
            span {
                font-size: 0.9rem;
                color: $primary;
            }
        }
        &__image {
            position: relative;
            width: 100%;
            height: 20rem;
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $primary-black, $alpha: 0.4);
                z-index: 5;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 1rem;
            }
            img {
                width: 100%;
                height: 100%;
                margin-bottom: 1.5rem;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            button {
                border: none;
                height: 2rem;
                padding: 0rem 1rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .work {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        padding: 0rem 3rem;
        summary {
            text-align: center;
            font-weight: 700;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            text-align: center;
            font-size: 1.2rem;
        }
        &__list {
            display: grid;
            grid-template-areas:
                'work__item--one work__item--two work__item--three'
                'work__item--four work__item--five work__item--six';
            row-gap: 3rem;
            column-gap: 3rem;
            margin-top: 3rem;
            width: 70vw;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: $card-border-size solid $primary-grey-4;
            border-radius: 0.5rem;
            padding-bottom: 1.5rem;
            h4 {
                font-size: 1rem;
                font-weight: 600;
                margin-top: 1.5rem;
            }
            span {
                font-size: 0.9rem;
                color: $primary;
            }
        }
        &__image {
            position: relative;
            width: 100%;
            height: 20rem;
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $primary-black, $alpha: 0.4);
                z-index: 5;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 1rem;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                margin-bottom: 1.5rem;
                border-top-right-radius: 0.5rem;
                border-top-left-radius: 0.5rem;
            }
            button {
                border: none;
                height: 2rem;
                padding: 0rem 1rem;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                color: $white;
                background-color: $primary;
                cursor: pointer;
                transition: all 0.05s linear;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(1);
                }
            }
        }
    }
}
