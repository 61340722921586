@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './variables';

* {
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}
*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
html {
    box-sizing: border-box;
}
body {
    min-height: 100vh;
    &::-webkit-scrollbar {
        width: 0;
    }
}
#app {
    width: 100vw;
}
