@import '../../styles/variables';

@media (min-width: $breakpoint-xs) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__intro {
            width: 100vw;
            background-color: $primary-black;
            text-align: center;
            color: $white;
            padding-top: 3rem;
            padding-bottom: 10rem;
            h3 {
                font-weight: 600;
                font-size: 1.5rem;
                padding: 0rem 1rem;
            }
            p {
                font-size: 0.85rem;
                text-align: center;
                margin-top: 1rem;
                padding: 0rem 2rem;
            }
        }
        &__quote {
            width: 85vw;
            background-color: $primary;
            z-index: 5;
            margin-top: -7rem;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0rem;
            h1 {
                font-size: 1.5rem;
                padding: 0rem 2rem;
                font-weight: 600;
            }
            &__form {
                background-color: $primary-dark;
                height: 2.6rem;
                border-radius: 1.3rem;
                margin-top: 2rem;
                width: 85%;
                display: flex;
                justify-content: space-between;
                input {
                    border: none;
                    background: transparent;
                    color: $white;
                    padding-left: 1rem;
                    width: 85%;
                    &::placeholder {
                        color: $white;
                        font-size: 0.8rem;
                    }
                }
                &--text {
                    display: none;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &--icon {
                    border: none;
                    height: 2.5rem;
                    width: 2.5rem;
                    border-radius: 1.25rem;
                    font-size: 1rem;
                    color: $primary;
                    background-color: $white;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        &__ninja {
            display: flex;
            padding: 5vh 4vh;
            &__content {
                h3 {
                    color: $primary-black;
                    font-size: 1.6rem;
                }
                h4 {
                    color: $primary;
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }
            }
            &__description {
                display: flex;
                span.quote {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
                summary {
                    max-width: 95%;
                    padding-top: 0.8rem;
                    span {
                        font-size: 1rem;
                    }
                }
            }
            &__image {
                display: none;
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__intro {
            width: 100vw;
            background-color: $primary-black;
            text-align: center;
            color: $white;
            padding-top: 3rem;
            padding-bottom: 10rem;
            h3 {
                font-weight: 600;
                font-size: 1.5rem;
                padding: 0rem 1rem;
            }
            p {
                font-size: 0.85rem;
                text-align: center;
                margin-top: 1rem;
                padding: 0rem 2rem;
            }
        }
        &__quote {
            width: 85vw;
            background-color: $primary;
            z-index: 5;
            margin-top: -7rem;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0rem;
            h1 {
                font-size: 1.5rem;
                padding: 0rem 2rem;
                font-weight: 600;
            }
            &__form {
                background-color: $primary-dark;
                height: 2.6rem;
                border-radius: 1.3rem;
                margin-top: 2rem;
                width: 85%;
                display: flex;
                justify-content: space-between;
                input {
                    border: none;
                    background: transparent;
                    color: $white;
                    padding-left: 1rem;
                    width: 85%;
                    &::placeholder {
                        color: $white;
                        font-size: 0.8rem;
                    }
                }
                &--text {
                    display: none;
                }
                &--icon {
                    border: none;
                    height: 2.5rem;
                    width: 2.5rem;
                    border-radius: 1.25rem;
                    font-size: 1rem;
                    color: $primary;
                    background-color: $white;
                }
            }
        }
        &__ninja {
            display: flex;
            padding: 5vh 4vh;
            &__content {
                h3 {
                    color: $primary-black;
                    font-size: 1.6rem;
                }
                h4 {
                    color: $primary;
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }
            }
            &__description {
                display: flex;
                span.quote {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
                summary {
                    max-width: 95%;
                    padding-top: 0.8rem;
                    span {
                        font-size: 1rem;
                    }
                }
            }
            &__image {
                display: none;
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__intro {
            height: 40vh;
            width: 100vw;
            background-color: $primary-black;
            text-align: center;
            color: $white;
            padding-top: 2rem;
            padding-bottom: 9rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
                font-weight: 700;
                font-size: 2.3rem;
            }
            p {
                max-width: 50vw;
                font-size: 1rem;
            }
        }
        &__quote {
            width: 70vw;
            background-color: $primary;
            z-index: 5;
            margin-top: -9rem;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 0rem;
            h1 {
                font-size: 2.5rem;
                max-width: 65%;
                font-weight: 600;
            }
            &__form {
                background-color: $primary-dark;
                height: 3.5rem;
                border-radius: 1.75rem;
                margin-top: 2rem;
                width: 35%;
                display: flex;
                input {
                    flex: 1;
                    border: none;
                    background: transparent;
                    color: $white;
                    padding-left: 2rem;
                    &::placeholder {
                        color: $white;
                    }
                }
                &--text {
                    display: block;
                    border: none;
                    height: 3.5rem;
                    padding: 0rem 2rem;
                    border-radius: 1.75rem;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $primary;
                    background-color: $white;
                }
                &--icon {
                    display: none;
                }
            }
        }
        &__ninja {
            display: flex;
            padding: 7vh 20vh 4vh 20vh;
            &__content {
                h3 {
                    color: $primary-black;
                    font-size: 2.7rem;
                }
                h4 {
                    color: $primary;
                    font-size: 2.7rem;
                    margin-bottom: 2rem;
                }
            }
            &__description {
                display: flex;
                span.quote {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
                summary {
                    max-width: 80%;
                    padding-top: 0.8rem;
                    span {
                        font-size: 1rem;
                    }
                }
            }
            &__image {
                display: block;
                flex: 1;
                img {
                    height: 30rem;
                    width: 30rem;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-lg) {
    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__intro {
            height: 35vh;
            width: 100vw;
            background-color: $primary-black;
            text-align: center;
            color: $white;
            padding-top: 2rem;
            padding-bottom: 7rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
                font-size: 2rem;
                font-weight: 700;
            }
            p {
                max-width: 85vw;
                font-size: 0.9rem;
            }
        }
        &__quote {
            width: 80vw;
            background-color: $primary;
            z-index: 5;
            margin-top: -3rem;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 0rem;
            h1 {
                font-size: 2rem;
                max-width: 80%;
                font-weight: 600;
            }
            &__form {
                background-color: $primary-dark;
                height: 3.1rem;
                border-radius: 1.55rem;
                margin-top: 2rem;
                width: 55%;
                display: flex;
                input {
                    flex: 1;
                    border: none;
                    background: transparent;
                    color: $white;
                    padding-left: 2rem;
                    &::placeholder {
                        color: $white;
                    }
                }
                &--text {
                    display: block;
                    border: none;
                    height: 3rem;
                    padding: 0rem 2rem;
                    border-radius: 1.5rem;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $primary;
                    background-color: $white;
                }
                &--icon {
                    display: none;
                }
            }
        }
        &__ninja {
            display: flex;
            padding: 7vh 12vh 4vh 12vh;
            &__content {
                h3 {
                    color: $primary-black;
                    font-size: 2.5rem;
                }
                h4 {
                    color: $primary;
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                }
            }
            &__description {
                display: flex;
                span.quote {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
                summary {
                    max-width: 80%;
                    padding-top: 0.8rem;
                    span {
                        font-size: 0.9rem;
                    }
                }
            }
            &__image {
                display: block;
                flex: 1;
                img {
                    height: 27rem;
                    width: 27rem;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__intro {
            height: 40vh;
            width: 100vw;
            background-color: $primary-black;
            text-align: center;
            color: $white;
            padding-top: 2rem;
            padding-bottom: 9rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
                font-weight: 700;
                font-size: 2.3rem;
            }
            p {
                max-width: 50vw;
                font-size: 1rem;
            }
        }
        &__quote {
            width: 70vw;
            background-color: $primary;
            z-index: 5;
            margin-top: -9rem;
            color: $white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3rem 0rem;
            h1 {
                font-size: 2.5rem;
                max-width: 65%;
                font-weight: 600;
            }
            &__form {
                background-color: $primary-dark;
                height: 3.5rem;
                border-radius: 1.75rem;
                margin-top: 2rem;
                width: 35%;
                display: flex;
                input {
                    flex: 1;
                    border: none;
                    background: transparent;
                    color: $white;
                    padding-left: 2rem;
                    &::placeholder {
                        color: $white;
                    }
                }
                &--text {
                    display: block;
                    border: none;
                    height: 3.5rem;
                    padding: 0rem 2rem;
                    border-radius: 1.75rem;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $primary;
                    background-color: $white;
                }
                &--icon {
                    display: none;
                }
            }
        }
        &__ninja {
            display: flex;
            padding: 7vh 20vh 4vh 20vh;
            &__content {
                h3 {
                    color: $primary-black;
                    font-size: 2.7rem;
                }
                h4 {
                    color: $primary;
                    font-size: 2.7rem;
                    margin-bottom: 2rem;
                }
            }
            &__description {
                display: flex;
                span.quote {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-right: 1.5rem;
                }
                summary {
                    max-width: 80%;
                    padding-top: 0.8rem;
                    span {
                        font-size: 1rem;
                    }
                }
            }
            &__image {
                display: block;
                flex: 1;
                img {
                    height: 30rem;
                    width: 30rem;
                }
            }
        }
    }
}
